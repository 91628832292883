/* eslint-disable import/prefer-default-export */
import axios from 'axios'
// eslint-disable-next-line import/no-extraneous-dependencies
import MockAdapter from 'axios-mock-adapter'
import AxiosInterceptor from '../hooks/AxiosInterceptorHook'
import {apiURLs, AddPDPCookie, } from './Utility'

/**
 * Custome axios instance for usermanager api
 * @param {string} companyId
 * @param {string} iPlanetDirectoryProCookie
 * @param {string} bearerToken
 */
// defaults for bearer token and planetprocookie are set in loginHook
// after call to logLogin
const createTMSyncDataAxiosInstance = () => {
  const axiosInstance = axios.create({
    baseURL: `${apiURLs.transactionMiddlewareBaseUrl}`,
    headers: {
      'Content-Type': 'application/json'
    },
    withCredentials: true

  })

  AddPDPCookie(axiosInstance)

  const interceptor = AxiosInterceptor(axiosInstance)

  return {axiosInstance, interceptor}
}

const TMSyncDataAxiosInstance = createTMSyncDataAxiosInstance()



export default {

    getTMSyncData: (source,destination, wkstId) => {

    // eslint-disable-next-line no-underscore-dangle
    if (window._env.TMSYNC_DATA) {

      // This sets the mock adapter on the default instance
      const mock = new MockAdapter(axios);

      // Mock any GET request to /users
      // arguments for reply are (status, data, headers)
      mock.onGet("/").reply(200,
        // eslint-disable-next-line no-underscore-dangle
        window._env.TMSYNC_DATA
      );

      return axios.get("/")
    }

    const params = wkstId ? {wkstId} : null
    return TMSyncDataAxiosInstance.axiosInstance({
      method: 'GET',
      params,
      url: `/sync/${source}/${destination}`
    })

  },
  sync: (source,destination, wkstId) => {

    if (window._env.TMSYNC_DATA) {

      // This sets the mock adapter on the default instance
      const mock = new MockAdapter(axios);

      // Mock any GET request to /users
      // arguments for reply are (status, data, headers)
      mock.onPost("/").reply(200,
        // eslint-disable-next-line no-underscore-dangle
        window._env.TMSYNC_DATA
      );

      return axios.post("/")
    }

    const params = wkstId ? {wkstId} : null
    return TMSyncDataAxiosInstance.axiosInstance({
      method: 'POST',
      params,
      url: `/sync/${source}/${destination}`
    }
    )
  },
  setJwtToken: jwtToken => {
    TMSyncDataAxiosInstance.axiosInstance.defaults.headers.common.Authorization = `Bearer ${jwtToken}`
  },

  setRequestInterceptor: (interceptor) => {
    TMSyncDataAxiosInstance.interceptor.setRequestInterceptor(interceptor)
  },
  setResponseInterceptor: (interceptor) => {
    TMSyncDataAxiosInstance.interceptor.setResponseInterceptor(interceptor)
  },

  ready: () => {
    return (TMSyncDataAxiosInstance.axiosInstance
      && TMSyncDataAxiosInstance.axiosInstance.defaults
      && TMSyncDataAxiosInstance.axiosInstance.defaults.headers
      && TMSyncDataAxiosInstance.axiosInstance.defaults.headers.common
      && TMSyncDataAxiosInstance.axiosInstance.defaults.headers.common.Authorization
      && true)
  }
}

